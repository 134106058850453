'use client';

export default function GlobalError({ reset }: { reset: () => void }) {
  return (
    <html lang='de'>
      <body>
        <h2>Something went wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
